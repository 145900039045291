import React from "react";
import "../styles/pages/privacy-policy.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="pcm-privacy-policy">
      <div className="pcm-privacy-policy__inner">
        <h1>FACTS</h1>
        <h2>
          WHAT DOES RETIREMENT INCOME ADVISORS LLC DO WITH YOUR PERSONAL
          INFORMATION?
        </h2>

        <h3>Why?</h3>
        <p>
          Financial companies choose how they share your personal information.
          Federal law gives consumers the right to limit some but not all
          sharing. Federal law also requires us to tell you how we collect,
          share, and protect your personal information. Please read this notice
          carefully to understand what Retirement Income Advisors LLC (d/b/a
          Preservation Capital Management) does.
        </p>

        <h3>What?</h3>
        <p>
          The types of personal information we collect and share depend on the
          product or service you have with us. This information can include:
        </p>
        <ul>
          <li>
            Personal information such as your name, social security number, and
            age;
          </li>
          <li>
            Experience and preference information such as your investment
            experience and risk tolerance;
          </li>
          <li>
            Financial information such as your income, account balances, and
            assets.
          </li>
        </ul>
        <p>
          When you are no longer our customer, we will stop sharing your
          information as described in this notice.
        </p>

        <h3>How?</h3>
        <p>
          All financial companies need to share personal information to run
          their everyday business. In the section below, we list the reasons
          financial companies can share their personal information; the reasons
          Retirement Income Advisors (“RIA”) chooses to share; and whether you
          can limit this sharing.
        </p>

        <table>
          <thead>
            <tr>
              <th>Reasons we can share your personal information</th>
              <th>Does RIA share?</th>
              <th>Can you limit this sharing?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                For our everyday business purposes— such as to process your
                transactions, maintain your account(s), respond to court orders
                and legal investigations, or report to credit bureaus
              </td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                For our marketing purposes— to offer our products and services
                to you
              </td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>For joint marketing with other financial companies</td>
              <td>No</td>
              <td>We do not share</td>
            </tr>
            <tr>
              <td>
                For our affiliates’ everyday business purposes— information
                about your transactions and experiences
              </td>
              <td>No</td>
              <td>We do not share and have no affiliates</td>
            </tr>
            <tr>
              <td>
                For our affiliates’ everyday business purposes— information
                about your creditworthiness
              </td>
              <td>No</td>
              <td>We do not share and have no affiliates</td>
            </tr>
            <tr>
              <td>For nonaffiliates to market to you</td>
              <td>No</td>
              <td>We do not share</td>
            </tr>
          </tbody>
        </table>

        <p>
          <strong>Questions?</strong> Please call us at (617) 669-8477.
        </p>

        <h2>Who we are</h2>
        <p>
          <strong>Who is providing this notice?</strong> Retirement Income
          Advisors LLC which also does business under the name Preservation
          Capital Management.
        </p>

        <h2>What we do</h2>
        <p>
          <strong>How does RIA protect my personal information?</strong> To
          protect your personal information from unauthorized access and use, we
          use security measures that comply with federal law. These measures
          include computer safeguards and secured files and offices.
        </p>
        <p>
          <strong>How does RIA collect my personal information?</strong> We
          collect your personal information, for example, when you:
        </p>
        <ul>
          <li>Apply to use or buy our services or products;</li>
          <li>Communicate and transact with us; and</li>
          <li>We open and manage your account.</li>
        </ul>
        <p>
          <strong>Why can’t I limit all sharing?</strong> Federal law gives you
          the right to limit only:
        </p>
        <ul>
          <li>
            Sharing for affiliates’ everyday business purposes—information about
            your creditworthiness;
          </li>
          <li>Affiliates from using your information to market to you; and</li>
          <li>Sharing with nonaffiliates to market to you.</li>
        </ul>
        <p>
          State laws and individual companies may give you additional rights to
          limit sharing.
        </p>

        <h2>Definitions</h2>
        <p>
          <strong>Affiliates:</strong> Companies related by common ownership or
          control. They can be financial and nonfinancial companies. RIA has no
          affiliates.
        </p>
        <p>
          <strong>Nonaffiliates:</strong> Companies not related by common
          ownership or control. They can be financial and nonfinancial
          companies. RIA does not share with nonaffiliates so they can market to
          you.
        </p>
        <p>
          <strong>Joint marketing:</strong> A formal agreement between
          nonaffiliated financial companies that together market financial
          products or services to you. RIA does not jointly market.
        </p>

        <p>
          <strong>Other important information</strong> We take our obligation to
          protect your privacy seriously. If you ever have any questions, please
          contact us at the information provided above.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
